<template>
  <div class="container--fluid pa-0">
    <the-header />
    <div class="container--fluid pa-0">
      <v-card flat color="primary" height="300" class="d-flex">
        <v-card
          flat
          color="secondary"
          height="89"
          class="border-r-0 ma-auto d-flex align-center justify-center px-10 py-4"
        >
          <p
            class="mb-0 line-h-62 font-weight--bold white--text letter-s-25 font-sofia"
            :class="$vuetify.breakpoint.xsOnly ? 'font-30' : 'font-40'"
          >
            Blog
          </p>
        </v-card>
      </v-card>
      <v-container :class="$vuetify.breakpoint.xsOnly ? 'pa-8' : 'px-8 py-71'">
        <v-row v-if="loading">
          <v-col cols="12" md="6" class="e-col">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6" class="e-col">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col
            cols="12"
            md="6"
            class="e-col"
            v-for="(item, index) in data"
            :key="index"
          >
            <router-link :to="`/blog-post/${item.slug}`">
              <div class="blog-wrap bg-color-grey-200">
                <div
                  class="image__wrap"
                  v-if="item.public_primary_image !== null"
                >
                  <img :src="item.public_primary_image.url" alt="" />
                </div>
                <div
                  v-else
                  class="width-180 height-180 bg-color-grey-200"
                ></div>
                <div class="content">
                  <p
                    class="font-18 line-h-22 font-weight--bold text-color-secondary-100 letter-s-25"
                  >
                    {{ item.title }}
                  </p>
                  <a
                    :href="`/blog-post/${item.slug}`"
                    class="font-14 line-h-14 font-weight--bold text-color-secondary-100 text-decoration-none"
                  >
                    <span class="mr-2">Read Post</span>
                    <img src="@/assets/images/blue-arrow.svg" />
                  </a>
                </div>
              </div>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";

export default {
  name: "AboutUs",
  components: { TheHeader, TheFooter },
  data: () => ({
    data: null,
    loading: true,
  }),
  mounted() {
    this.getAllBlog();
  },
  methods: {
    async getAllBlog() {
      this.loading = true;
      await this.$store.dispatch("static/ALL_BLOG").then((res) => {
        if (res.result === "success") {
          this.data = res.data.data;
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style></style>
